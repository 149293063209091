import React from "react";

const TopNavBar: React.FC = () => (
    <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center justify-content-between">

            <h1 className="logo"><a href="index.html">My PWA</a></h1>
            <nav id="navbar" className="navbar">
                <ul>
                    <li><a className="nav-link active" href="/">Home</a></li>
                    <li><a className="nav-link" href="/about">About</a></li>
                </ul>
                <i className="bi bi-list mobile-nav-toggle"></i>
            </nav>

        </div>
    </header>

);

export default TopNavBar;